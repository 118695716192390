import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Hotkeys from "react-hot-keys";

import {GlobalContext} from "../../../ctx";
import {postByUrl} from "../../../v3MethodsService";

import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

import "./style.scss";

const LimitedAccess = () => {
    const history = useHistory()
    const location = useLocation()
    const toast = useRef()

    const [state, setState] = useState(null)
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(false)
    const [limitedAccess, setLimitedAccess] = useState(false)

    const [global, setGlobal] = useContext(GlobalContext)
    const token = global && global.token

    useEffect(() => {
        if (state) {
            const notValid = formData.map(f => !state[f.key]).filter(f => f)
            setIsValid(!notValid.length)
        } else {
            setIsValid(false)
        }
    }, [state]);

    useEffect(() => {
        setLimitedAccess(location?.pathname !== '/' && !localStorage.getItem('_am_limited_tkn') && !token)
    }, [location, token]);

    const inputHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        setIsValid(false);
        if (state && state.password === state.repeat_password) {
            if (isValidPassword(state.password)) {
                postByUrl('clientAuth/createSoft', {login: state.login, password: state.password})
                    .then(resp => {
                        if (resp && resp.success) {
                            localStorage.setItem('_am_limited_tkn', 'true')
                            setLimitedAccess(false)
                        }
                    })
                return
            }
            toast.current.show({ severity: "error", life: 4000, summary: "Пароль должен быть: мин. 8 симв., латиница, мин. 1 буква, мин. 1 цифра" });
            return;
        }
        toast.current.show({ severity: "error", life: 4000, summary: "Пароли не совпадают" });
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return limitedAccess && (
        <div className='limited-access__wrapper'>
            <Toast ref={toast} position='top-right'/>
            <div className='limited-access'>
                <div className='auth2__container login'>
                    <div className='auth2__title'>
                        Для дальнейшего просмотра пройдите авторизацию
                    </div>
                    <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                        <div className='auth2__inputs'>
                            {formData.map(f => renderField(state, f, inputHandler, error))}
                            <Button label='Войти' onClick={sendHandler} className='auth2__button' disabled={!isValid}/>
                        </div>
                    </Hotkeys>
                </div>
            </div>
        </div>
    )
}

const formData = [
    {key: "login", placeholder: "Введите логин", type: "input", size: "full"},
    {key: "password", placeholder: "Введите пароль", type: "password", size: "full"},
    {key: "repeat_password", placeholder: "Повторите пароль", type: "password", size: "full"},
]

const renderField = (state, field, inputHandler, error) => {
    switch (field.type) {
        case 'input':
            return <InputText id={field.key} key={field.key} value={state?.[field.key] || ''}
                              onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__input${error ? ' auth2__input_invalid' : ''}`}/>
        case 'password': return <Password id={field.key} key={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__password${error ? ' auth2__password_invalid' : ''}`} toggleMask feedback={false}/>
        default: return null
    }
}

const isValidPassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z\W_]{8,}$/;
    return regex.test(password);
};

export default LimitedAccess